import { jsx } from "react/jsx-runtime";
import { lazy, useMemo } from "react";
const LazyFormError = lazy(
  () => import("./form-error-DrHvX6rN.js").then((module) => ({
    default: module.FormError
  }))
);
function FormError({
  id: _id,
  name: _name,
  ...props
}) {
  return /* @__PURE__ */ jsx(LazyFormError, { ...props });
}
const useFormErrorContent = () => {
  return useMemo(
    () => ({
      formError: {
        component: FormError
      }
    }),
    []
  );
};
export {
  LazyFormError as FormError,
  useFormErrorContent
};
